import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { forwardRef, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchItem,
} from "src/plugins/redux/reducers/RFPsReducer";

import {
    fetchSubmissions,
    selectSubmissions,
    selectStatus,
    selectDraftSubmissions,
    deleteProduct,
    selectTrashSubmissions
} from "src/plugins/redux/reducers/ProductsReducer";
import {
    setHeaderTitle,
    setHeaderActionBtns,
    setHeaderArrowBack,
    setShowSearch,
    setTitleLink,
    setShowPremiumHeader
} from "src/plugins/redux/reducers/HeaderReducer";
import Papa from "papaparse";
import toast from "react-hot-toast";
import ProductsFilter from "./ProductsFilter";
import RFPNameFilter from "./RFPNameFilter";
import formatDate from "src/componenets/DateFormat";
import EditSubmissionModal from "./EditSubmissionsModal";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { me } from "src/plugins/redux/reducers/AuthReducer";
import SupplierUnlockPremium from "src/componenets/SupplierUnlockPremium";
import SupplierDummyProducts from "src/componenets/SupplierDummyProducts";
import PremiumModalComponent from "src/componenets/PremiumModalComponent";
import detailsFormatDate from "src/componenets/DetailsDateFormat";

const Submissions = forwardRef((props: any, ref: any): JSX.Element => {


    const dispatch = useDispatch();
    const location = useLocation();
    const status = useSelector(selectStatus);
    const [filters, setFilters] = useState<any>({});
    const [sortBy, setSortBy] = useState();
    const [sortType, setSortType] = useState("ASC");
    const [products, setProducts] = useState([]);
    const [columnToSort, setColumnToSort] = useState("");
    const [editModal, setEditModal] = useState<boolean>(false);
    const [PremiumModal, setPremiumModal] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<any>();
    const [productUpdated, setProductUpdated] = useState<boolean>(false)
    const [showDeletePopUp, setShowDeletePopUp] = useState<boolean>(false);
    const isDraftRoute = location.pathname.includes('/draft');
    const isTrashRoute = location.pathname.includes('/trash');
    const fetchedProducts = useSelector(isTrashRoute ? selectTrashSubmissions : (isDraftRoute ? selectDraftSubmissions : selectSubmissions));
    const currentUser = useSelector(me);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleTogglePremium = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };


    useEffect(() => {
        setProducts(fetchedProducts);
    }, [fetchedProducts])
    useEffect(() => {
        if (productUpdated) {
            dispatch(fetchSubmissions());
            toast.success('Submission Updated')
            setProductUpdated(false);

        }
    }, [productUpdated])

    useEffect(() => {
        dispatch(setHeaderTitle('Submissions'));
        dispatch(setHeaderActionBtns([]));
        dispatch(setHeaderArrowBack(false));
        dispatch(setShowSearch(false));
        dispatch(fetchSubmissions());
    }, []);

    useEffect(() => {
        dispatch(setShowPremiumHeader(true));
    }, [location, dispatch]);

    const toggleEditModal = () => {
        setEditModal(!editModal);
    }

    const handleCloseDeletePopup = () => {
        setShowDeletePopUp(false);
    }

    const handleDelteProduct = async () => {
        const response = await dispatch(deleteProduct({ 'id': selectedProduct, 'trash': true }));
        if (response?.payload?.success) {
            toast.success('Submission Deleted Successfully');
            setProducts(products.filter((product) => product.id != selectedProduct))
            dispatch(fetchSubmissions());
        } else {
            toast.error('Error Deleting Submissions Please Try again')
        }
        setShowDeletePopUp(false);
    }


    const extractNumericalValue = (str) => {
        if (str) {
            const numericalValue = parseFloat(str.match(/\d+(\.\d+)?/));
            return isNaN(numericalValue) ? 0 : numericalValue;
        } else {
            return 0;
        }
    };

    const handleTypeSort = (columnName: any, numeric: boolean = false) => {
        const sortedProducts = [...products].sort((a: any, b: any) => {
            if (numeric) {
                if (sortType === 'ASC') {
                    return extractNumericalValue(a[columnName]) - extractNumericalValue(b[columnName]);
                } else {
                    return extractNumericalValue(b[columnName]) - extractNumericalValue(a[columnName]);
                }
            } else {
                let valueA;
                let valueB;
                if (columnName == 'catinfo' || columnName == 'supplierInfo' || columnName == 'producerInfo' || columnName == 'agency') {
                    valueA = a[columnName]?.name?.toUpperCase();
                    valueB = b[columnName]?.name?.toUpperCase();
                } else if (columnName == 'client') {
                    valueA = a.rfpInfo?.client;
                    valueB = b.rfpInfo?.client;
                } else if (columnName == 'rfp_title') {
                    valueA = a.rfpInfo?.rfp_title;
                    valueB = b.rfpInfo?.rfp_title;
                } else if (columnName == 'submission_date') {
                    valueA = formatDate(a.created_at);
                    valueB = formatDate(b.created_at);
                }
                else {
                    valueA = a[columnName]?.toUpperCase();
                    valueB = b[columnName]?.toUpperCase();
                }
                if (sortType === 'ASC') {
                    return valueA?.localeCompare(valueB);
                } else {
                    return valueB?.localeCompare(valueA);
                }
            }
        });

        setSortBy(columnName);
        setSortType((current) => (current === "ASC" ? "DESC" : "ASC"));
        setColumnToSort(columnName);
        setProducts(sortedProducts);
    }

    useEffect(() => {
        let filteredProducts: any = [...fetchedProducts];
        for (const key in filters) {
            if (key == 'category_id' && filters[key]) {
                filteredProducts = filteredProducts?.filter((product: any) => product.catinfo?.id == filters[key]);
            } if (key == 'category_type_id' && filters[key]) {
                filteredProducts = filteredProducts?.filter((product: any) => product.categoryTypeInfo?.id == filters[key]);
            } if (key == 'category_sub_type_id' && filters[key]) {
                filteredProducts = filteredProducts?.filter((product: any) => product.categorySubTypeInfo?.id == filters[key]);
            } if (key == 'rfp_id' && filters[key]) {
                filteredProducts = filteredProducts?.filter((product: any) => product.rfp_id == filters[key]);
            }if(key=='pour_id' && filters[key]){
                filteredProducts = filteredProducts?.filter((product: any) => product.pour_id == filters[key]);
            }
        }

        setProducts(filteredProducts);
    }, [filters]);

    const exportToCsv = () => {
        var productsLocal: any = [];
        products.map((product: any) => {
            let unitSizes = "";

            if (product.productSizeInfo && product.productSizeInfo.length > 0) {
                unitSizes = product.productSizeInfo
                    .filter((size: any) => size?.name != null && size?.name !== '')
                    .map((size: any) => size.name)
                    .join(", ");
            }

            const product_sell_sheets = product.assets?.find((asset: any) => asset.assets_type == 'sell_sheets')?.asset_file;
            const image_front = product.assets?.find((asset: any) => asset.assets_type == 'product_image_front')?.asset_file;
            const image_back = product.assets?.find((asset: any) => asset.assets_type == 'product_image_back')?.asset_file;
            const label_front = product.assets?.find((asset: any) => asset.assets_type == 'label_image_front')?.asset_file;
            const label_back = product.assets?.find((asset: any) => asset.assets_type == 'label_image_back')?.asset_file;
            const recipes = product.recipes?.map((recipe: any) => { return recipe.asset_file })?.join(',');
            const logos = product.assets?.find((asset: any) => asset.assets_type == 'logos')?.asset_file;

            productsLocal.push({
                Category: product.catinfo ? product.catinfo.name : "",
                "Product Name": product.name ? product.name : "",
                "Product Description": product.description ? product.description : '',
                Supplier: product.supplierInfo ? product.supplierInfo.name : "",
                Producer: product.producerInfo ? product.producerInfo.name : "",
                Type: product.categoryTypeInfo ? product.categoryTypeInfo.name : "",
                "Varietal/Sup-Type": product.categorySubTypeInfo
                    ? product.categorySubTypeInfo.name
                    : "",
                Placement: product.itemPourInfo ? product.itemPourInfo.name : "",
                'ABV %': product.abv ? product.abv : "",
                "Prominent Flavors": product.prominentFlavorsInfo ? product.prominentFlavorsInfo?.map((flavor: any) => { if (flavor?.name) { return flavor.name } }).join(',') : "",
                "Country of Origin": product.countryOfOriginInfo ? product.countryOfOriginInfo?.name : "",
                "Region / Appellation": product.regionsInfo ? product.regionsInfo?.name : "",
                "Unit Size": product.productSizeInfo ? product.productSizeInfo[0]?.name : "",
                "Item Closure": product.itemClosureInfo
                    ? product.itemClosureInfo.name
                    : "",
                "National Average Unit Price - Case 1": product.national_pricing ? product.national_pricing : "",
                "Price/Ounce": product.price_per_ounce ? product?.price_per_ounce : "",
                "Units per Case": product.units_per_case ? product?.units_per_case : "",
                "Other Available Sizes": unitSizes ? unitSizes : "",
                "Product Sell Sheet": product_sell_sheets ? product_sell_sheets : '',
                "Product Image (front)": image_front ? image_front : '',
                "Product Image (back)": image_back ? image_back : '',
                "Label Image (front)": label_front ? label_front : '',
                "Label Image (back)": label_back ? label_back : '',
                "Recipes": recipes ? recipes : '',
                "Logos": logos ? logos : '',
                "Video Links": product.videos ? product.videos : '',
                "Awards / Accolades": product.awards ? product.awards : '',
                "Notes, if any": product.notes ? product.notes : '',
            });
        });
        var csv = Papa.unparse(productsLocal);

        var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        var csvURL = window.URL.createObjectURL(csvData);

        var testLink = document.createElement("a");
        testLink.href = csvURL;
        testLink.setAttribute("test", "test.csv");
        testLink.click();
    };

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between m-2 mt-10">
                <div className="d-flex">
                    <RFPNameFilter
                        filters={filters}
                        setFilters={setFilters}
                        setSortBy={setSortBy}
                    />
                    <ProductsFilter
                        type={filters?.category_id}
                        subType={filters?.category_type_id}
                        filters={filters}
                        setFilters={setFilters}
                        setSortBy={setSortBy}
                    />
                </div>
                <button
                    type="button"
                    className="btn btn-pill btn-outline-custom-primary font-weight-bold"
                    onClick={() => exportToCsv()}
                >
                    Export CSV
                    <i className="la la-upload"></i>
                </button>
            </div>
            <div
                style={{ "max-height": "70vh", "overflow-y": "auto", minWidth: '1200px', 'border-top-left-radius': '3rem', 'border-top-right-radius': '3rem' }}
                className="custom-scroll"
            >
                <table className="table submissions-table rounded-top-pill table-striped table-lg text-center justify-content-center" style={{ 'overflow': 'overflow !important;' }}>
                    <thead class="bg-custom-secondary">
                        <tr className="text-white">
                            <th
                                style={{ minWidth: "125px", cursor: "pointer", 'border-top-left-radius': '2rem' }}
                                onClick={() => handleTypeSort("supplierInfo")}
                            >
                                Status
                                {columnToSort === 'supplierInfo' &&
                                    (sortType == "ASC" ? (
                                        <ArrowDropUpIcon />
                                    ) : (
                                        <ArrowDropDownIcon />
                                    ))}
                            </th>
                            <th
                                onClick={() => handleTypeSort("client")}
                                style={{ minWidth: "125px", cursor: "pointer", width: "120px" }}
                            >
                                Client
                                {columnToSort === 'client' &&
                                    (sortType == "ASC" ? (
                                        <ArrowDropUpIcon />
                                    ) : (
                                        <ArrowDropDownIcon />
                                    ))}
                            </th>
                            <th
                                onClick={() => handleTypeSort("rfp_title")}
                                style={{ cursor: "pointer", width: "100px" }}
                            >
                                RFP Name
                                {columnToSort === 'rfp_title' &&
                                    (sortType == "ASC" ? (
                                        <ArrowDropUpIcon />
                                    ) : (
                                        <ArrowDropDownIcon />
                                    ))}
                            </th>
                            <th
                                onClick={() => handleTypeSort("agency")}
                                style={{ cursor: "pointer" }}
                            >
                                Managed By
                                {columnToSort === 'agency' &&
                                    (sortType == "ASC" ? (
                                        <ArrowDropUpIcon />
                                    ) : (
                                        <ArrowDropDownIcon />
                                    ))}
                            </th>
                            <th
                                onClick={() => handleTypeSort("name")}
                                style={{ minWidth: "140px", cursor: "pointer" }}
                            >
                                Product Name
                                {columnToSort === "name" &&
                                    (sortType == "ASC" ? (
                                        <ArrowDropUpIcon />
                                    ) : (
                                        <ArrowDropDownIcon />
                                    ))}
                            </th>
                            <th
                                onClick={() => handleTypeSort("producerInfo")}
                                style={{ cursor: "pointer" }}
                            >
                                Producer
                                {columnToSort === "itemPourInfo" &&
                                    (sortType == "ASC" ? (
                                        <ArrowDropUpIcon />
                                    ) : (
                                        <ArrowDropDownIcon />
                                    ))}
                            </th>

                            <th onClick={() => handleTypeSort("national_pricing", true)} style={{ cursor: "pointer" }}>
                                Unit Price
                                {columnToSort === 'national_pricing' &&
                                    (sortType == "ASC" ? (
                                        <ArrowDropUpIcon />
                                    ) : (
                                        <ArrowDropDownIcon />
                                    ))}
                            </th>
                            <th onClick={() => handleTypeSort('submission_date')} style={{ cursor: "pointer" }}>
                                Submission Date
                                {columnToSort === 'submission_date' &&
                                    (sortType == "ASC" ? (
                                        <ArrowDropUpIcon />
                                    ) : (
                                        <ArrowDropDownIcon />
                                    ))}
                            </th>
                            <th style={{ 'border-top-right-radius': '2rem' }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products?.map((product: any, index: number) => (
                            <tr key={'product' + index}>
                                <td>
                                    {product.status == 'active' ? 'completed' : product.status}
                                </td>
                                <td>{product.rfpInfo?.client ? product.rfpInfo?.client : '-'}</td>
                                <td>{product?.rfpInfo?.rfp_title ? product?.rfpInfo?.rfp_title : '-'}</td>
                                <td>
                                    {product?.agency?.name ? product?.agency?.name : '-'}
                                </td>
                                <td>
                                    {product.name}
                                </td>
                                <td>
                                    {product?.producerInfo?.name ? product?.producerInfo?.name : '-'}
                                </td>
                                <td>
                                    {product.national_pricing ? `$${product.national_pricing}` : "-"}
                                </td>
                                <td>
                                    {product.created_at ? (product.status == 'draft' ? '-' : detailsFormatDate(product.created_at)) : "-"}
                                </td>
                                <td className="d-flex align-items-center justify-content-center">
                                    {(!currentUser?.is_premium && !currentUser?.is_trial) && (
                                        <>
                                            <Link
                                                to={`/submission/${product.id}`}
                                                state={{ product: product }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <g clip-path="url(#clip0_4_3167)">
                                                        <path
                                                            d="M12 6C15.79 6 19.17 8.13 20.82 11.5C19.17 14.87 15.79 17 12 17C8.21 17 4.83 14.87 3.18 11.5C4.83 8.13 8.21 6 12 6ZM12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 9C13.38 9 14.5 10.12 14.5 11.5C14.5 12.88 13.38 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 10.12 10.62 9 12 9ZM12 7C9.52 7 7.5 9.02 7.5 11.5C7.5 13.98 9.52 16 12 16C14.48 16 16.5 13.98 16.5 11.5C16.5 9.02 14.48 7 12 7Z"
                                                            fill="#003ACE"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_4_3167">
                                                            <rect width="24" height="24" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </Link>
                                            <div
                                                onClick={handleTogglePremium}
                                                style={{ cursor: "pointer", marginBottom: '2px', marginLeft: '15px' }}
                                            >
                                                <i className="fa fa-edit" style={{ color: "#C3CCD6" }}></i>
                                            </div>
                                            <div
                                                onClick={handleTogglePremium}
                                                style={{ cursor: "pointer", marginBottom: '2px', marginLeft: '15px' }}
                                            >
                                                <i className="fa fa-trash" style={{ color: "#C3CCD6" }}></i>
                                            </div>
                                        </>

                                    )}
                                    {(currentUser?.is_premium || currentUser?.is_trial) && (
                                        <>

                                            <Link
                                                className="mr-4"
                                                to={`/submission/${product.id}`}
                                                state={{ product: product }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <g clip-path="url(#clip0_4_3167)">
                                                        <path
                                                            d="M12 6C15.79 6 19.17 8.13 20.82 11.5C19.17 14.87 15.79 17 12 17C8.21 17 4.83 14.87 3.18 11.5C4.83 8.13 8.21 6 12 6ZM12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 9C13.38 9 14.5 10.12 14.5 11.5C14.5 12.88 13.38 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 10.12 10.62 9 12 9ZM12 7C9.52 7 7.5 9.02 7.5 11.5C7.5 13.98 9.52 16 12 16C14.48 16 16.5 13.98 16.5 11.5C16.5 9.02 14.48 7 12 7Z"
                                                            fill="#003ACE"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_4_3167">
                                                            <rect width="24" height="24" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </Link>
                                            {new Date() > new Date(product?.rfpInfo?.rfp_end_date) || !product?.rfpInfo ? (
                                                <div style={{ marginBottom: '2px' }}>
                                                    <i className="fa fa-edit text-muted" style={{ color: 'gray', cursor: 'not-allowed' }}></i>
                                                </div>

                                            ) : (
                                                <div
                                                    onClick={() => {
                                                        setSelectedProduct(product);
                                                        toggleEditModal();
                                                    }}
                                                    style={{ cursor: "pointer", marginBottom: '2px' }}
                                                >
                                                    <i className="fa fa-edit text-primary-blue"></i>
                                                </div>
                                            )}
                                            {product.status != 'trash' && (
                                                <>
                                                    {(product.status != 'trash' && (new Date() > new Date(product?.rfpInfo?.rfp_end_date))) || !product?.rfpInfo ? (
                                                        <div style={{ marginBottom: '2px' }}>
                                                            <i className="fa fa-trash text-muted" style={{ color: 'gray', cursor: 'not-allowed', marginLeft: '15px' }}></i>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            onClick={() => {
                                                                setSelectedProduct(product?.id);
                                                                setShowDeletePopUp(true);
                                                            }}
                                                            style={{ cursor: "pointer", marginBottom: '2px', marginLeft: '15px' }}
                                                        >
                                                            <i className="fa fa-trash text-primary-blue"></i>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Dialog onClose={handleCloseDeletePopup} open={showDeletePopUp} className="p-4">
                <DialogTitle>Are you sure you want to delete this Submission?</DialogTitle>
                <div className="d-flex align-items-center justify-content-center gap-3 mb-3">
                    <button className="btn btn-outline-custom-primary btn-pill" onClick={handleCloseDeletePopup}>Close</button>
                    <button className="btn btn-danger btn-pill" onClick={handleDelteProduct}>Delete</button>
                </div>
            </Dialog>
            {status === "loading" && (
                <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ minHeight: "50vh" }}
                >
                    <div className="spinner-border text-custom-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
            {status === "error" && (
                <div
                    className="d-flex flex-column gap-2 align-items-center justify-content-center"
                    style={{ minHeight: "50vh" }}
                >
                    <strong className="text-center fs-1 text-custom-primary">
                        Error occurred while fetching items.
                    </strong>
                    <button
                        className="btn btn-custom-primary btn-pill"
                        onClick={() => dispatch(fetchSubmissions())}
                    >
                        <i className="fa fa-redo-alt" />
                        Retry
                    </button>
                </div>
            )}

            <EditSubmissionModal
                editModal={editModal}
                toggleEditModal={toggleEditModal}
                selectedProduct={selectedProduct}
                setProductUpdated={setProductUpdated}
            />
            <PremiumModalComponent
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
            />
        </div>
    );
});

export default Submissions;
