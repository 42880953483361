import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

import {
    updateProduct,
    addPriceByState,
} from "src/plugins/redux/reducers/ProductsReducer";
import {
    selectProminentFlavors,
    fetchProminentFlavors,
    selectRegions,
    fetchRegions,
    selectSizes,
    fetchUnitSizes,
    fetchUnitsPerCase,
    fetchItemClosures,
    selectItemClosures,
    fetchProducers,
    selectProducers,
    fetchCountryOfOrigins,
    selectCountries,
    addDropdownData,
} from "src/plugins/redux/reducers/DropdownDataReducer";
import PricingByStateModal from "./PricingByStateModal";
import selectStyles from "src/themes/Helpers";
import {
    selectCategories,
    fetchCategories,
    poursOptions,
    selectPours,
    addSubType
} from "src/plugins/redux/reducers/CategoriesReducer";
import {
    fetchSuppliers,
    selectSuppliers,
} from "src/plugins/redux/reducers/AdminReducers";
import { currentUserSelector } from "src/plugins/redux/reducers/AuthReducer";
import { uploadImageToS3 } from "src/plugins/redux/reducers/RFPsReducer";
import { useParams, useLocation } from "react-router-dom";
import { Divider, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';



const EditSubmissionModal = forwardRef((props: any, ref: any): JSX.Element => {

    const location = useLocation();
    const isDraftRoute = location.pathname.includes('/draft');

    const [selectedProduct, setSelectedProduct] = useState<any>();

    useEffect(() => {
        if (props.selectedProduct) {
            setSelectedProduct(props.selectedProduct)
        }
    }, [props])


    const {
        handleSubmit,
        control,
        formState: { errors },
        register,
        reset,
        getValues
    } = useForm({
        defaultValues: selectedProduct,
    });

    const customSelectStyles = selectStyles.formSelectStyles;
    const { id } = useParams();
    const dispatch = useDispatch();
    const categories = useSelector(selectCategories);
    const prominent_flavors = useSelector(selectProminentFlavors);
    const regions = useSelector(selectRegions);
    const sizes = useSelector(selectSizes);
    const itemClosures = useSelector(selectItemClosures);
    const suppliers = useSelector(selectSuppliers);
    const Producers = useSelector(selectProducers);
    const countries = useSelector(selectCountries);
    const currentUser = useSelector(currentUserSelector);
    const pours = useSelector(selectPours);
    const isSupplier = currentUser?.role_id == 3;

    const [files, setFiles] = useState({});
    const [selectedStates, setSelectedStates] = useState<any>([]);
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedType, setSelectedType] = useState<any>();
    const [selectedVarietal, setSelectedVarietal] = useState<any>();
    const [subTypes, setSubTypes] = useState([]);
    const [statesPricing, setStatePricing] = useState();
    const [selectedCat, setSelectedCat] = useState("");
    const [otherType, setOtherType] = useState(false);
    const [otherTypeNew, setOtherTypeNew] = useState("");
    const [mainSize, setMainSize] = useState();
    const [selectedPour, setSelectedPour] = useState<any>();
    const [selectedItemClosure, setSelectedItemClosure] = useState<any>();
    const [otherSupplier, setOtherSupplier] = useState({});
    const [Producer, setProducer] = useState();
    const [prominentFlavors, setProminentFlavors] = useState();
    const [countryOfOrigin, setCountryOfOrigin] = useState();
    const [region, setRegions] = useState();
    const [size, setSize] = useState();
    const [recipes, setRecipes] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState<any>();
    const [selectedTypeValue, setSelectedTypeValue] = useState<any>();


    useEffect(() => {
        dispatch(fetchCategories());
        dispatch(fetchProminentFlavors());
        dispatch(fetchRegions());
        dispatch(fetchUnitsPerCase());
        dispatch(fetchItemClosures());
        dispatch(fetchSuppliers());
        dispatch(fetchProducers());
        dispatch(fetchCountryOfOrigins());
        dispatch(poursOptions());
    }, []);


    useEffect(() => {
        if (selectedCat?.value) {
            dispatch(fetchUnitSizes(selectedCat?.value));
        }
    }, [selectedCat]);

    useEffect(() => {
        const sortedSubTypes = [...(selectedType?.sub_type ?? [])].sort((a, b) =>
            a?.name?.localeCompare(b?.name)
        );

        const subTypes = sortedSubTypes
            ?.map((subtype, index) => {
                if (index === sortedSubTypes.length - 1) {
                    return [
                        { label: subtype.name, value: subtype.id },
                        { label: "Other", value: "other" },
                    ];
                }
                return { label: subtype.name, value: subtype.id };
            })
            .flat();

        setSubTypes(subTypes);
    }, [selectedType]);


    const handleCategoryChange = () => {
        setSelectedType(null);
        setSelectedTypeValue(null);
        setSelectedVarietal(null);
        setProminentFlavors([])
        setCountryOfOrigin(null);
        setSize([]);
        setSelectedItemClosure(null);
        setMainSize(null);
        setSelectedPour(null);
        setRegions(null);
    }

    useEffect(() => {
        setSelectedStates([]);
        setStatePricing(null);


        if (selectedProduct) {
            setSelectedCat({
                label: selectedProduct?.catinfo?.name,
                value: selectedProduct?.catinfo?.id
            });
            setSelectedCategory(categories?.find((category) => category?.id == selectedProduct?.catinfo?.id));
            setSelectedTypeValue({
                label: selectedProduct?.categoryTypeInfo?.name,
                value: selectedProduct?.categoryTypeInfo?.id
            });
            setSelectedPour({ label: selectedProduct?.itemPourInfo?.name, value: selectedProduct?.itemPourInfo?.id });
            setProducer({
                value: selectedProduct?.producerInfo?.id,
                label: selectedProduct?.producerInfo?.name,
            });
            setProminentFlavors(
                selectedProduct?.prominentFlavorsInfo?.map((flavor) => ({
                    value: flavor.id,
                    label: flavor.name,
                }))
            );
            setSelectedSupplier({ label: selectedProduct?.supplierInfo?.name, value: selectedProduct?.supplierInfo?.id });
            setSelectedItemClosure({ label: selectedProduct?.itemClosureInfo?.name, value: selectedProduct.itemClosureInfo?.id });
            setCountryOfOrigin({
                label: countries?.find((country) => country?.id == selectedProduct?.countryOfOriginInfo?.id)?.name,
                value: countries?.find((country) => country?.id == selectedProduct?.countryOfOriginInfo?.id)?.id,
            });
            setRegions({
                label: selectedProduct?.regionsInfo?.name,
                value: selectedProduct?.regionsInfo?.id,
            });
            const mainProductSize = selectedProduct?.productSizeInfo?.map(({ id, name }, index) => {
                if (index === 0) {
                    return {
                        value: id,
                        label: name,
                    };
                }
                return null;
            }).filter(Boolean)[0];
            setMainSize(mainProductSize);
            setSize(
                selectedProduct?.productSizeInfo
                    ?.slice(1)
                    .map(({ id, name }) => ({
                        value: id,
                        label: name,
                    }))
            );
            setSelectedVarietal({ label: selectedProduct?.categorySubTypeInfo?.name, value: selectedProduct?.categorySubTypeInfo?.id });
            setVideo(selectedProduct?.videos);
            setRecipes(selectedProduct.recipes?.map((recipe: any) => {
                return recipe.asset_file;
            }) ?? []);
            const prominentFlavorIds = selectedProduct.prominentFlavorsInfo?.map(
                (data: any) => data.id
            );
            const joinedFlavorIds = prominentFlavorIds?.join(",");
            const sizeIds = selectedProduct?.productSizeInfo?.map((data: any) => data?.id).slice(1);

            const joinedSizeIds = sizeIds?.join(",");
            reset({
                ...selectedProduct,
                sizes: joinedSizeIds,
                prominent_flavors_id: joinedFlavorIds,
                main_size: mainProductSize?.value,
            });
            setFiles((prevFiles) => {
                const updatedFiles: any = { ...prevFiles };
                selectedProduct?.assets?.forEach((data: any) => {
                    updatedFiles[data.assets_type] = data.asset_file;
                });
                return updatedFiles;
            });
            for (let i = 0; i < selectedProduct?.PricingByState?.length; i++) {
                const item = selectedProduct.PricingByState[i];
                const stateExist = selectedStates?.some(
                    (state) => state.state.state_id == item.state_id
                );

                if (stateExist) {
                    for (let j = 0; j < selectedStates.length; j++) {
                        const state = selectedStates[j];
                        if (state.state.state_id == item.state_id) {
                            state.prices.push({
                                price: item.price,
                                distributor: item.distributor,
                            });
                            break;
                        }
                    }
                } else {
                    selectedStates.push({
                        state: item,
                        prices: [
                            {
                                price: item.price,
                                distributor: item.distributor,
                            },
                        ],
                    });
                }
            }
            setSelectedStates([...selectedStates]);
            const restructuredData = [];
            for (const item of selectedStates) {
                const stateId = item.state.id;
                for (const priceItem of item.prices) {
                    const distributor = priceItem.distributor;
                    const price = priceItem.price;
                    const restructuredItem = {
                        state_id: stateId,
                        distributor: distributor,
                        price: price,
                    };
                    restructuredData.push(restructuredItem);
                }
            }
            setStatePricing(restructuredData);
        }
    }, [selectedProduct]);






    const handleRecipesFileChnage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const recipesFiles = event.target.files;
        console.log(recipesFiles);
        if (recipesFiles) {
            Object.keys(recipesFiles).forEach(async (key) => {
                const file = recipesFiles[key];
                if (file instanceof Blob) {
                    const file_url = await dispatch(
                        uploadImageToS3({ file: file, dirName: "products" })
                    );
                    setRecipes((prevRecipes) => [...prevRecipes, file_url ? file_url.payload : ""]);
                }
            });
        }
    };


    const handleFileChange = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const file = event.target.files[0];
        var key = event.target.name;
        if (file) {
            var filesLocal = files;
            const file_url = await dispatch(
                uploadImageToS3({ file: file, dirName: "products" })
            );
            setFiles({ ...files, [key]: file_url ? file_url.payload : "" });
        }
    };

    const handleRemoveFile = (fileType) => {
        setFiles({ ...files, [fileType]: null });
    };
    const handleCreateDropdownData = async (type, inputValue) => {
        let newData;
        if (type == "unitSizes") {
            newData = await dispatch(
                addDropdownData({
                    type: type,
                    name: inputValue,
                    category_id: selectedCategory?.id
                })
            );
        } else if (type == "subType") {
            newData = await dispatch(
                addSubType({
                    'category_type_id': selectedType?.id ? selectedType?.id : selectedType?.value,
                    name: inputValue
                })
            );
        } else {
            newData = await dispatch(
                addDropdownData({
                    type: type,
                    name: inputValue,
                })
            );
        }

        switch (type) {
            case "countryOfOrigin":
                dispatch(fetchCountryOfOrigins());
                break;
            case "prominentFlavors":
                dispatch(fetchProminentFlavors());
                break;
            case "producers":
                dispatch(fetchProducers());
                break;
            case "suppliers":
                dispatch(fetchSuppliers());
                break;
            case "regions":
                dispatch(fetchRegions());
            case "unitSizes":
                dispatch(fetchUnitSizes(selectedCategory?.id));
                break;
            case "subType":
                setSubTypes([...subTypes, { label: inputValue, value: newData?.payload?.success }]);
                break;
            default:
                console.log(`not found`);
        }

        return newData?.payload?.success;
    };
    const [video, setVideo] = useState("");
    const [otherFlavors, setOtherFlavors] = useState(false);
    const [addFlavors, setAddFlavors] = useState();
    useEffect(() => {
        if (prominentFlavors) {
            const tr = prominentFlavors?.some((other) => other.value == "other");
            setOtherFlavors(tr);
        }
    }, [prominentFlavors, size]);


    const handleSaveDraft = async () => {
        const data = getValues();
        console.log('data', data);

        if (!data?.name || data?.name == '') {
            const element = document.getElementById('nameInput');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
            return;
        }

        data.company_id = currentUser.company_id;
        data.user_id = currentUser.id;

        data.rfp_id = id;
        data.status = 'draft';

        size?.unshift(mainSize);
        if (!data.national_pricing || data.national_pricing == '') {
            const prices = statesPricing?.map(item => parseFloat(item.price));
            const sum = prices?.reduce((acc, curr) => acc + curr, 0);
            const averagePrice = sum / prices?.length;
            if (!isNaN(averagePrice / data.units_per_case)) {
                data.price_per_unit = averagePrice;
                data.national_pricing = averagePrice;
            }
        } else {
            if (data.national_pricing !== null) {
                data.price_per_unit = data.national_pricing;
            } else {
                data.price_per_unit = null;
            }
        }
        if (currentUser?.role_id == 3) {
            data.supplier_id = currentUser.company_id;
        }
        for (const key in data) {
            if (data[key] === undefined || data[key] === null || data[key] == '') {
                delete data[key];
            }

            if (typeof data[key] === "number") {
                data[key] = data[key]?.toString();
            }
        }
        Object.keys(files)?.forEach((file) => {
            data[file] = files[file];
        });

        data.recipes = JSON.stringify(recipes);

        if (selectedVarietal?.value == "other" && selectedVarietal?.subName) {
            const newData = await dispatch(
                addSubType({
                    'category_type_id': data.category_type_id,
                    name: selectedVarietal?.subName,
                })
            );
            data.category_sub_type_id = newData?.payload?.success.toString();
        } else {
            data.category_sub_type_id = selectedVarietal?.value;
        }
        if (otherSupplier?.value == "other" && otherSupplier?.name) {
            const newData = await dispatch(
                addDropdownData({
                    type: "suppliers",
                    name: otherSupplier?.name,
                })
            );
            data.supplier_id = newData?.payload?.success.toString();
        }
        if (Producer?.value == "other" && Producer?.name) {
            const newData = await dispatch(
                addDropdownData({
                    type: "producers",
                    name: Producer?.name,
                })
            );
            data.producer_id = newData?.payload?.success.toString();
        }
        if (countryOfOrigin?.value == "other" && countryOfOrigin?.name) {
            const newData = await dispatch(
                addDropdownData({
                    type: "countryOfOrigin",
                    name: countryOfOrigin?.name,
                })
            );
            data.country_of_origin_id = newData?.payload?.success.toString();
        }
        if (region?.value == "other" && region?.name) {
            const newData = await dispatch(
                addDropdownData({
                    type: "regions",
                    name: region?.name,
                })
            );
            data.region_id = newData?.payload?.success.toString();
        }
        if (addFlavors && otherFlavors) {
            const newData = await dispatch(
                addDropdownData({
                    type: "prominentFlavors",
                    name: addFlavors,
                })
            );
            const newFlavor = newData?.payload?.success.toString();
            const flavor = prominentFlavors.map((item) => item.value).join(",");
            data.prominent_flavors_id = flavor.replace("other", newFlavor);
        }


        if (!data.sizes?.includes(mainSize.value)) {
            data.sizes = mainSize.value + ',' + data.sizes;
        }
        data.id = selectedProduct?.id;
        const response = await dispatch(updateProduct(data));
        const product_id = response.payload.success;
        if (product_id) {
            statesPricing?.map((price) => {
                price.product_id = product_id;
            });

            const updatedStatesPricing = statesPricing.map(price => ({
                ...price,
                product_id: product_id
            }));
            dispatch(addPriceByState({
                statesPricing: statesPricing,
                product_id: product_id
            }));
        }
        if (product_id) {
            setSize(null);
            setProminentFlavors(null);
            setProducer(null);
            setCountryOfOrigin(null);
            setRegions(null);
            setVideo(null);
            setSelectedCategory(null);
            setSelectedCat(null);
            setFiles({});
            setSelectedStates([]);
            setStatePricing(null);
            setSelectedProduct(null);
            reset({});
            props.toggleEditModal();
            props.setProductUpdated(true);
        }

    }



    const handleSubmitProduct = async (data: any) => {
        data.company_id = currentUser.company_id;
        data.user_id = currentUser.id;
        data.rfp_id = id;
        data.status = 'active';

        if (!data.national_pricing || data.national_pricing == '') {
            const prices = statesPricing?.map(item => parseFloat(item.price));
            const sum = prices?.reduce((acc, curr) => acc + curr, 0);
            const averagePrice = sum / prices?.length;
            if (!isNaN(averagePrice / data.units_per_case)) {
                data.price_per_unit = averagePrice;
                data.national_pricing = averagePrice;
            }
        } else {
            if (data.national_pricing !== null) {
                data.price_per_unit = data.national_pricing;
            } else {
                data.price_per_unit = null;
            }
        }
        if (currentUser?.role_id == 3) {
            data.supplier_id = currentUser.company_id;
        }
        for (const key in data) {
            if (typeof data[key] === "number") {
                data[key] = data[key].toString();
            }
        }
        Object.keys(files).forEach((file) => {
            data[file] = files[file];
        });

        data.recipes = JSON.stringify(recipes);

        if (selectedVarietal?.value == "other" && selectedVarietal?.subName) {
            const newData = await dispatch(
                addSubType({
                    'category_type_id': data.category_type_id,
                    name: selectedVarietal?.subName,
                })
            );
            data.category_sub_type_id = newData?.payload?.success.toString();
        } else {
            data.category_sub_type_id = selectedVarietal?.value;
        }
        if (otherSupplier?.value == "other" && otherSupplier?.name) {
            const newData = await dispatch(
                addDropdownData({
                    type: "suppliers",
                    name: otherSupplier?.name,
                })
            );
            data.supplier_id = newData?.payload?.success.toString();
        }
        if (Producer?.value == "other" && Producer?.name) {
            const newData = await dispatch(
                addDropdownData({
                    type: "producers",
                    name: Producer?.name,
                })
            );
            data.producer_id = newData?.payload?.success.toString();
        }
        if (countryOfOrigin?.value == "other" && countryOfOrigin?.name) {
            const newData = await dispatch(
                addDropdownData({
                    type: "countryOfOrigin",
                    name: countryOfOrigin?.name,
                })
            );
            data.country_of_origin_id = newData?.payload?.success.toString();
        }
        if (region?.value == "other" && region?.name) {
            const newData = await dispatch(
                addDropdownData({
                    type: "regions",
                    name: region?.name,
                })
            );
            data.region_id = newData?.payload?.success.toString();
        }
        if (addFlavors && otherFlavors) {
            const newData = await dispatch(
                addDropdownData({
                    type: "prominentFlavors",
                    name: addFlavors,
                })
            );
            const newFlavor = newData?.payload?.success.toString();
            const flavor = prominentFlavors.map((item) => item.value).join(",");
            data.prominent_flavors_id = flavor.replace("other", newFlavor);
        }

        if (!data.sizes.includes(mainSize.value)) {
            data.sizes = mainSize.value + ',' + data.sizes;
        }

        data.id = selectedProduct?.id;

        const response = await dispatch(updateProduct(data));
        const product_id = response.payload.success;
        if (product_id) {
            statesPricing?.map((price) => {
                price.product_id = product_id;
            });

            const updatedStatesPricing = statesPricing.map(price => ({
                ...price,
                product_id: product_id
            }));
            dispatch(addPriceByState({
                statesPricing: statesPricing,
                product_id: product_id
            }));
        }
        if (product_id) {
            setSize(null);
            setProminentFlavors(null);
            setProducer(null);
            setCountryOfOrigin(null);
            setRegions(null);
            setVideo(null);
            setSelectedStates([]);
            setStatePricing(null);
            setSelectedCategory(null);
            setSelectedCat(null);
            setFiles({});
            setSelectedProduct(null);
            reset({});
            props.toggleEditModal();
            props.setProductUpdated(true);
        }
    };


    const [secondModalOpen, setSecondModalOpen] = useState(false);


    return (
        <div>
            <Modal
                id="addProductModal"
                isOpen={props.editModal}
                toggle={props.toggleEditModal}
                scrollable={true}
                style={{ display: secondModalOpen ? "none" : "block" }}
                onClosed={() => {
                    setSize(null);
                    setProminentFlavors(null);
                    setProducer(null);
                    setCountryOfOrigin(null);
                    setRegions(null);
                    setVideo(null);
                    setSelectedCategory(null);
                    setSelectedCat(null);
                    setFiles({});
                    setSelectedStates([]);
                    setStatePricing(null);
                    setSelectedProduct(null);
                    reset({});
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "16px 24px",
                    }}
                >
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        Submit Product
                    </Typography>
                    <IconButton onClick={props.toggleEditModal}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Divider />
                <ModalBody className="custom-scroll">
                    <form onSubmit={handleSubmit(handleSubmitProduct)}>
                        <div className="row">

                            <div className="col-12 mb-3">
                                <label className="w-100">Category*</label>
                                <div class="d-flex align-items-center gap-1">
                                    <Controller
                                        control={control}
                                        {...register("category_id", { required: true })}
                                        name="category_id"
                                        render={({ field: { onChange, value, ref, name } }) => (
                                            <Select
                                                className={errors.category_id ? "invalid-input" : ""}
                                                placeholder=""
                                                styles={customSelectStyles}
                                                value={selectedCat}
                                                options={
                                                    categories?.length > 0 &&
                                                    categories.map((category) => ({
                                                        value: category.id,
                                                        label: category.name,
                                                    }))
                                                }
                                                onChange={(category) => {
                                                    setSelectedCat(category);
                                                    setSelectedCategory(
                                                        categories.find(
                                                            (categoryItem) =>
                                                                categoryItem.id === category.value
                                                        )
                                                    );
                                                    onChange(category.value);
                                                    handleCategoryChange();
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.category_id && (
                                        <Tooltip title="Category is required" arrow>
                                            <ErrorIcon color="error" />
                                        </Tooltip>
                                    )}
                                </div>
                            </div>

                            <div className="col-12 mb-3" id="nameInput">
                                <label className="w-100">Product Name*</label>
                                <div class="d-flex align-items-center gap-1">
                                    <input
                                        id="name"
                                        {...register("name", { required: true })}
                                        type="text"
                                        className={"form-control rounded-pill " + (errors.name ? "invalid-input" : "")}
                                        readOnly
                                    />
                                    {errors?.name && (
                                        <Tooltip title="Product Name is required" arrow>
                                            <ErrorIcon color="error" />
                                        </Tooltip>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <label className="w-100">Product Description</label>
                                <textarea
                                    {...register("description")}
                                    className="form-control rounded-xl"
                                    style={{ resize: "none" }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                {!isSupplier && (
                                    <>
                                        <label className="w-100">Supplier*</label>

                                        <div class="d-flex align-items-center gap-1">
                                            <Controller
                                                name="supplier_id"
                                                control={control}
                                                {...register("supplier_id", { required: true })}
                                                value={selectedSupplier?.value}
                                                render={({
                                                    field: { onChange, value, ref, name },
                                                }) => (
                                                    <CreatableSelect
                                                        value={selectedSupplier}
                                                        styles={customSelectStyles}
                                                        className={errors.supplier_id ? "invalid-input" : ""}
                                                        placeholder=""
                                                        onCreateOption={async (newValue) => {
                                                            const newSupplier = await handleCreateDropdownData(
                                                                "suppliers",
                                                                newValue
                                                            );
                                                            setSelectedSupplier({ label: newValue, value: newSupplier });
                                                            onChange(newSupplier);
                                                        }}
                                                        options={suppliers
                                                            ?.map((data, index) => {
                                                                if (index === suppliers.length - 1) {
                                                                    return [
                                                                        { value: data.id, label: data.name },
                                                                        { value: "other", label: "Other" },
                                                                    ];
                                                                } else {
                                                                    return {
                                                                        value: data.id,
                                                                        label: data.name,
                                                                    };
                                                                }
                                                            })
                                                            .flat()?.sort((a, b) => a.label.localeCompare(b.label))}
                                                        onChange={(val) => {
                                                            if (val?.value == "other") {
                                                                setOtherSupplier(val);
                                                                onChange(val.value);
                                                                setSelectedSupplier(val);
                                                            } else {
                                                                setOtherSupplier(val);
                                                                onChange(val.value);
                                                                setSelectedSupplier(val);
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors.supplier_id && (
                                                <Tooltip title="Supplier is required" arrow>
                                                    <ErrorIcon color="error" />
                                                </Tooltip>
                                            )}
                                        </div>
                                    </>
                                )}
                                {otherSupplier?.value == "other" && (
                                    <div className="row col-12">
                                        <div className="col-12 mb-4">
                                            <label className="w-100">
                                                Please Enter Your Option Of Supplier
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control  rounded-pill"
                                                onChange={(e) =>
                                                    setOtherSupplier({
                                                        value: "other",
                                                        label: "Other",
                                                        name: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="col-12 mb-3">
                                <label className="w-100">Producer{(selectedCategory?.id != 3 && selectedCategory?.id != 4) ? '*' : ''}</label>
                                <div class="d-flex align-items-center gap-1">
                                    <Controller
                                        name="producer_id"
                                        {...register("producer_id", { required: (selectedCategory?.id != 3 && selectedCategory?.id != 4) ? true : false })}
                                        control={control}
                                        value={Producer?.value}
                                        render={({ field: { onChange, value, ref, name } }) => (
                                            <CreatableSelect
                                                styles={customSelectStyles}
                                                onCreateOption={async (newValue) => {
                                                    const newProducer = await handleCreateDropdownData("producers", newValue);
                                                    setProducer({ label: newValue, value: newProducer });
                                                    onChange(newProducer);
                                                }}
                                                value={Producer}
                                                className={errors.producer_id ? "invalid-input" : ""}
                                                placeholder=""
                                                options={
                                                    Producers?.length > 0 &&
                                                    Producers?.map((data, index) => {
                                                        if (index === Producers.length - 1) {
                                                            return [
                                                                { value: data.id, label: data.name },
                                                                { value: "other", label: "Other" },
                                                            ];
                                                        } else {
                                                            return { value: data.id, label: data.name };
                                                        }
                                                    }).flat()?.sort((a, b) => a.label.localeCompare(b.label))
                                                }
                                                onChange={(val) => {
                                                    if (val.value == "other") {
                                                        setProducer(val);
                                                        onChange(val.value);
                                                    } else {
                                                        onChange(val.value);
                                                        setProducer(val);
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.producer_id && (
                                        <Tooltip title="Producer is required" arrow>
                                            <ErrorIcon color="error" />
                                        </Tooltip>
                                    )}
                                </div>
                                {Producer?.value == "other" && (
                                    <div className="col-12 mb-4">
                                        <label className="w-100">
                                            Please Enter Your Option Of Producer
                                        </label>
                                        <input
                                            defaultValue=''
                                            type="text"
                                            {...register("producer_other", { required: (selectedCategory?.id != 3 && selectedCategory?.id != 4) ? true : false })}
                                            className="form-control  rounded-pill"
                                            onChange={(e) =>
                                                setProducer({
                                                    value: "other",
                                                    label: "Other",
                                                    name: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                )}
                            </div>

                            {selectedCategory && (
                                <div className="col-12 mb-3">
                                    <label className="w-100">Type*</label>
                                    <div class="d-flex align-items-center gap-1">
                                        <Controller
                                            {...register("category_type_id", {
                                                required: true,
                                            })}
                                            control={control}
                                            render={({
                                                field: { onChange, value, ref, name },
                                            }) => (
                                                <Select
                                                    value={selectedTypeValue}
                                                    styles={customSelectStyles}
                                                    placeholder=""
                                                    className={errors.category_type_id ? "invalid-input" : ""}
                                                    options={
                                                        selectedCategory?.types?.length > 0 &&
                                                        selectedCategory.types
                                                            ?.map((type, index) => {
                                                                if (
                                                                    index ===
                                                                    selectedCategory.types.length - 1
                                                                ) {
                                                                    return [
                                                                        { value: type.id, label: type.name },
                                                                    ];
                                                                } else {
                                                                    return {
                                                                        value: type.id,
                                                                        label: type.name,
                                                                    };
                                                                }
                                                            })
                                                            .flat()
                                                    }
                                                    onChange={(newValue) => {
                                                        if (newValue.value === "other") {
                                                            setOtherType(true);
                                                            setSelectedType({
                                                                value: "other",
                                                                name: "Other",
                                                            });
                                                            setSelectedTypeValue(newValue);
                                                        } else {
                                                            setOtherType(false);
                                                            setSelectedType(
                                                                selectedCategory.types.find(
                                                                    (type) => type.id == newValue.value
                                                                )
                                                            );
                                                            setSelectedTypeValue(newValue);
                                                            onChange(newValue.value);
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.category_type_id && (
                                            <Tooltip title="Type is required" arrow>
                                                <ErrorIcon color="error" />
                                            </Tooltip>
                                        )}
                                    </div>
                                </div>
                            )}

                            {otherType && (
                                <div className="col-12 mb-4">
                                    <label className="w-100">
                                        Please Enter Your Option Of Type*
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control rounded-pill"
                                        onChange={(e) => setOtherTypeNew(e.target.value)}
                                    />
                                </div>
                            )}

                            {(selectedCategory?.id == 2 || (selectedCategory?.id == 1 && selectedType?.id == 81)) && (
                                <div className="col-12 mb-3">
                                    <label className="w-100">
                                        Varietal/Sub-type*
                                    </label>
                                    <div class="d-flex align-items-center gap-1">
                                        <Controller
                                            name="category_sub_type_id"
                                            control={control}
                                            {...register("category_sub_type_id", { required: (selectedCategory?.id == 2 || (selectedCategory?.id == 1 && selectedType?.id == 81)) ? true : false })}
                                            render={({
                                                field: { onChange, value, ref, name },
                                            }) => (
                                                <CreatableSelect
                                                    placeholder=""
                                                    className={errors.category_sub_type_id ? "invalid-input" : ""}
                                                    styles={customSelectStyles}
                                                    value={selectedVarietal}
                                                    onCreateOption={async (newValue) => {
                                                        const newSubType = await handleCreateDropdownData('subType', newValue);
                                                        setSelectedVarietal({ label: newValue, value: newSubType });
                                                        onChange(newSubType);
                                                    }}
                                                    options={subTypes?.sort((a, b) => a.label.localeCompare(b.label))}
                                                    onChange={(val) => {
                                                        if (val?.value == "other") {
                                                            setSelectedVarietal(val);
                                                        } else {
                                                            setSelectedVarietal(val);
                                                            onChange(val.value);
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.category_sub_type_id && (
                                            <Tooltip title="Varietal is required" arrow>
                                                <ErrorIcon color="error" />
                                            </Tooltip>
                                        )}
                                    </div>
                                </div>
                            )}
                            {(selectedVarietal?.id == "other" ||
                                selectedVarietal?.value == "other") && (
                                    <div className="col-12 mb-4">
                                        <label className="w-100">
                                            Please Enter Your Option Of Varietal
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control rounded-pill"
                                            onChange={(e) =>
                                                setSelectedVarietal({
                                                    value: "other",
                                                    label: "Other",
                                                    name: "Other",
                                                    subName: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                )}

                            {selectedCategory?.id && selectedCategory?.id != 4 && (
                                <div className="col-12 mb-3">
                                    <label className="w-100">Placement Type{selectedCategory?.id == '3' ? '*' : ''}</label>
                                    <div className="d-flex align-items-center gap-1">
                                        <Controller
                                            {...register("pour_id", { required: selectedCategory?.id == '3' })}
                                            value={selectedPour?.value}
                                            control={control}
                                            render={({ field: { onChange, value, ref, name } }) => (
                                                <Select
                                                    className={errors.pour_id ? "invalid-input" : ""}
                                                    styles={customSelectStyles}
                                                    placeholder=""
                                                    value={selectedPour}
                                                    options={
                                                        pours?.length > 0 &&
                                                        pours?.filter((pour) => {
                                                            return pour.category_id == selectedCategory?.id;
                                                        })?.map((pour) => ({
                                                            value: pour.id,
                                                            label: pour.name,
                                                        }))
                                                    }
                                                    onChange={(pour) => {
                                                        onChange(pour.value);
                                                        setSelectedPour(pour);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.pour_id && (
                                            <Tooltip title="Placement Type is required" arrow>
                                                <ErrorIcon color="error" />
                                            </Tooltip>)}
                                    </div>
                                </div>
                            )}

                            <div className="col-12 mb-3">
                                <label className="w-100">ABV %*</label>
                                <div class="d-flex align-items-center ">
                                    <input
                                        {...register("abv", { required: true })}
                                        type="number"
                                        className={"form-control rounded-pill " + (errors.abv ? "invalid-input" : "")}
                                    />
                                    {errors.abv && (
                                        <Tooltip title="ABV is required" arrow>
                                            <ErrorIcon color="error" />
                                        </Tooltip>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <label className="w-100">Prominent Flavors</label>
                                <Controller
                                    name="prominent_flavors_id"
                                    {...register("prominent_flavors_id")}
                                    control={control}
                                    render={({ field: { onChange, value, ref, name } }) => (
                                        <CreatableSelect
                                            styles={customSelectStyles}
                                            isMulti
                                            value={prominentFlavors}
                                            placeholder="Select flavors, up to 3"
                                            onCreateOption={async (newValue) => {
                                                const newFlavor = await handleCreateDropdownData(
                                                    "prominentFlavors",
                                                    newValue
                                                );
                                                setProminentFlavors([...prominentFlavors, { label: newValue, value: newFlavor }]);
                                            }}
                                            options={
                                                prominent_flavors?.length > 0 &&
                                                prominent_flavors
                                                    ?.map((data, index) => {
                                                        if (index === prominent_flavors.length - 1) {
                                                            return [
                                                                { value: data.id, label: data.name },
                                                                { value: "other", label: "Other" },
                                                            ];
                                                        } else {
                                                            return { value: data.id, label: data.name };
                                                        }
                                                    })
                                                    .flat()?.sort((a, b) => a.label.localeCompare(b.label))
                                            }
                                            onChange={(val) => {
                                                if (val.length <= 3) {
                                                    const selectedIds = val
                                                        .map((item) => item.value)
                                                        .join(",");
                                                    setProminentFlavors(val);
                                                    onChange(selectedIds);
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </div>

                            {otherFlavors && (
                                <div className="col-12 mb-4">
                                    <label className="w-100">
                                        Please Enter Your Option Of Prominent Flavors
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control  rounded-pill"
                                        onChange={(e) => setAddFlavors(e.target.value)}
                                    />
                                </div>
                            )}

                            <div className="col-12 mb-3">
                                <label className="w-100">Country of Origin*</label>
                                <div class="d-flex align-items-center gap-1">
                                    <Controller
                                        {...register("country_of_origin_id", {
                                            required: true,
                                        })}
                                        value={countryOfOrigin?.value}
                                        control={control}
                                        render={({ field: { onChange, value, ref, name } }) => (
                                            <CreatableSelect
                                                styles={customSelectStyles}
                                                value={countryOfOrigin}
                                                placeholder=""
                                                className={errors.country_of_origin_id ? "invalid-input" : ""}
                                                onCreateOption={async (newValue) => {
                                                    const newCountry = await handleCreateDropdownData(
                                                        "countryOfOrigin",
                                                        newValue
                                                    );
                                                    setCountryOfOrigin({ label: newValue, value: newCountry });
                                                    onChange(newCountry);
                                                }}
                                                options={
                                                    countries?.length > 0 &&
                                                    countries
                                                        ?.map((data, index) => {
                                                            if (index === countries.length - 1) {
                                                                return [
                                                                    { value: data.id, label: data.name },
                                                                    { value: "other", label: "Other" },
                                                                ];
                                                            } else {
                                                                return { value: data.id, label: data.name };
                                                            }
                                                        })
                                                        .flat()?.sort((a, b) => a.label.localeCompare(b.label))
                                                }
                                                onChange={(val) => {
                                                    if (val.value == "other") {
                                                        setCountryOfOrigin(val);
                                                        onChange(val.value);
                                                    } else {
                                                        setCountryOfOrigin(val);
                                                        onChange(val.value);
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.country_of_origin_id && (
                                        <Tooltip title="Country of Origin is required" arrow>
                                            <ErrorIcon color="error" />
                                        </Tooltip>
                                    )}
                                </div>
                            </div>

                            {countryOfOrigin?.value == "other" && (
                                <div className="col-12 mb-4">
                                    <label className="w-100">
                                        Please Enter Your Option Of Country
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control  rounded-pill"
                                        onChange={(e) =>
                                            setCountryOfOrigin({
                                                value: "other",
                                                label: "Other",
                                                name: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            )}

                            <div className="col-12 mb-3">
                                <label className="w-100">Region / Appellation {selectedCategory?.id == 2 ? '*' : ''}</label>
                                <div class="d-flex align-items-center gap-1">
                                    <Controller
                                        {...register("region_id", {
                                            required: selectedCategory?.id == 2 ? true : false,
                                        })}
                                        value={region?.value}
                                        control={control}
                                        className={errors.region_id ? "invalid-input" : ""}
                                        render={({ field: { onChange, value, ref, name } }) => (
                                            <CreatableSelect
                                                styles={customSelectStyles}
                                                placeholder=""
                                                value={region}
                                                onCreateOption={async (newValue) => {
                                                    const newRegion = await handleCreateDropdownData("regions", newValue);
                                                    setRegions({ label: newValue, value: newRegion });
                                                    onChange(newRegion);
                                                }}
                                                options={
                                                    regions?.length > 0 &&
                                                    regions
                                                        .map((data, index) => {
                                                            if (index === regions.length - 1) {
                                                                return [
                                                                    { value: data.id, label: data.name },
                                                                    { value: "other", label: "Other" },
                                                                ];
                                                            } else {
                                                                return { value: data.id, label: data.name };
                                                            }
                                                        })
                                                        .flat()?.sort((a, b) => a.label.localeCompare(b.label))
                                                }
                                                onChange={(val) => {
                                                    if (val.value == "other") {
                                                        onChange(val.value);
                                                        setRegions(val);
                                                    } else {
                                                        onChange(val.value);
                                                        setRegions(
                                                            regions?.find((region) => {
                                                                region.id === val.value,
                                                                    region.name === val.label;
                                                            })
                                                        );
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.region_id && (
                                        <Tooltip title="Region is required" arrow>
                                            <ErrorIcon color="error" />
                                        </Tooltip>
                                    )}
                                </div>
                            </div>

                            {region?.value == "other" && (
                                <div className="col-12 mb- mb-3">
                                    <label className="w-100">
                                        Please Enter Your Option Of Region
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control  rounded-pill"
                                        onChange={(e) =>
                                            setRegions({
                                                value: "other",
                                                label: "Other",
                                                name: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            )}
                            <div className="col-12">
                                {selectedCategory && sizes && (
                                    <>
                                        <label className="w-100">Unit Size*</label>
                                        <div class="d-flex align-items-center gap-1">
                                            <Controller
                                                control={control}
                                                {...register("main_size", { required: true })}
                                                value={mainSize?.value}
                                                render={({ field: { onChange, value, ref, name } }) => (
                                                    <Select
                                                        placeholder=""
                                                        className={errors.main_size ? "invalid-input" : ""}
                                                        value={mainSize}
                                                        styles={customSelectStyles}
                                                        options={
                                                            sizes?.length > 0 &&
                                                            sizes?.filter((size) => size.category_id == selectedCategory?.id)
                                                                .map((data, index) => {
                                                                    if (index === sizes.length - 1) {
                                                                        return [
                                                                            { value: data.id, label: data.name },
                                                                        ];
                                                                    } else {
                                                                        return { value: data.id, label: data.name };
                                                                    }
                                                                })
                                                                .flat()
                                                        }
                                                        onChange={(val) => {
                                                            onChange(val.value);
                                                            setMainSize(val)
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors.main_size && (
                                                <Tooltip title="Unit Size is required" arrow>
                                                    <ErrorIcon color="error" />
                                                </Tooltip>
                                            )}
                                        </div>

                                    </>
                                )}
                            </div>


                            {selectedCategory?.id !== 3 &&
                                selectedCategory?.id !== 4 && (
                                    <div className="col-12">
                                        <label className="w-100">Item Closure{selectedCategory?.id == 2 ? '*' : ''}</label>
                                        <div class="d-flex align-items-center gap-1">
                                            <Controller
                                                {...register("item_closure_id", { required: selectedCategory?.id == 2 ? true : false })}
                                                value={selectedItemClosure?.value}
                                                control={control}
                                                className={errors.item_closure_id ? "invalid-input" : ""}
                                                render={({
                                                    field: { onChange, value, ref, name },
                                                }) => (
                                                    <Select
                                                        styles={customSelectStyles}
                                                        value={selectedItemClosure}
                                                        placeholder=""
                                                        options={
                                                            itemClosures &&
                                                            itemClosures?.map((data) => ({
                                                                value: data.id,
                                                                label: data.name,
                                                            }))
                                                        }
                                                        onChange={(val) => { onChange(val.value); setSelectedItemClosure(val); }}
                                                    />
                                                )}
                                            />
                                            {errors.item_closure_id && (
                                                <Tooltip title="Item Closure is required" arrow>
                                                    <ErrorIcon color="error" />
                                                </Tooltip>
                                            )}
                                        </div>
                                    </div>
                                )}


                            <div className="col-12 mb-3">
                                <label className="w-100">
                                    Enter National Average Unit Price, or State by State Pricing Below
                                </label>
                                <input
                                    type="number"
                                    step="any"
                                    className="form-control mb-3  rounded-pill"
                                    onKeyDown={(e) => {
                                        if (e.which === 38 || e.which === 40) {
                                            e.preventDefault();
                                        }
                                    }}
                                    {...register("national_pricing")}
                                    placeholder="Enter case 1 pricing, unless otherwise instructed"
                                />
                                <a className={`cursor-pointer`} onClick={() => {
                                    setSecondModalOpen(true);
                                    $("#pricingByStateModal").modal("show");
                                }}>
                                    <i className="la la-plus"></i>Add price by State
                                </a>
                                {selectedStates.length > 0 && (
                                    <div
                                        className="card rounded-xl p-3 w-100 bg-custom-light custom-scroll"
                                        style={{ maxHeight: "250px", overflowY: "auto" }}
                                    >
                                        {selectedStates?.map((state: any) => (
                                            <div className="py-5 border-bottom border-white">
                                                <strong>{state.state.name}</strong>
                                                <div>
                                                    {state.prices.map((price: any) => (
                                                        <div className="d-flex flex-column align-items-start border-bottom border-white py-3">
                                                            <span className="text-muted">
                                                                Distributor:{" "}
                                                                <strong>{price.distributor}</strong>
                                                            </span>
                                                            <span className="text-muted">
                                                                Price: <strong>{price.price}</strong>
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {selectedStates.length > 0 &&
                                    !(
                                        <div
                                            className="card rounded-xl p-3 w-100 bg-custom-light custom-scroll"
                                            style={{ maxHeight: "250px", overflowY: "auto" }}
                                        >
                                            {selectedStates?.map((state) => (
                                                <div className="py-5 border-bottom border-white">
                                                    <strong>{state.state.name}</strong>
                                                    <div>
                                                        {state.prices?.map((price) => (
                                                            <div className="d-flex flex-column align-items-start border-bottom border-white py-3">
                                                                <span className="text-muted">
                                                                    Distributor:{" "}
                                                                    <strong>{price.distributor}</strong>
                                                                </span>
                                                                <span className="text-muted">
                                                                    Price: <strong>{price.price}</strong>
                                                                </span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                            </div>

                            <div className="col-12">
                                <label className="w-100">Units per Case* (#)</label>
                                <div class="d-flex align-items-center gap-1">
                                    <input
                                        {...register("units_per_case", { required: true })}
                                        type="number"
                                        name="units_per_case"
                                        onKeyDown={(e) => {
                                            if (e.which === 38 || e.which === 40) {
                                                e.preventDefault();
                                            }
                                        }}
                                        className={"form-control mb-3 rounded-pill " + (errors.units_per_case ? "invalid-input" : "")}
                                    />
                                    {errors.units_per_case && (
                                        <Tooltip title="Units per Case is required" arrow>
                                            <ErrorIcon color="error" />
                                        </Tooltip>
                                    )}
                                </div>
                            </div>

                            <div className="col-12">
                                <label className="w-100">Other Available Sizes</label>
                                <Controller
                                    control={control}
                                    {...register("sizes")}
                                    render={({ field: { onChange, value, ref, name } }) => (
                                        <Select
                                            isMulti
                                            value={size}
                                            placeholder="Select Available Sizes"
                                            styles={customSelectStyles}
                                            options={
                                                sizes?.length > 0 &&
                                                sizes?.filter((size) => size.category_id == selectedCategory?.id)
                                                    .map((data: any, index: any) => {
                                                        if (index === sizes.length - 1) {
                                                            return [
                                                                { value: data.id, label: data.name },
                                                            ];
                                                        } else {
                                                            return { value: data.id, label: data.name };
                                                        }
                                                    })
                                                    .flat()
                                            }
                                            onChange={(val: any) => {
                                                const selectedIds = val
                                                    .map((item) => item.value)
                                                    .join(",");
                                                setSize(val);
                                                onChange(selectedIds);
                                            }}
                                        />
                                    )}
                                />

                            </div>


                            <div className="row mb-4 col-12">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-6 col-md-6">
                                            <label className="w-100">Product Sell Sheet</label>
                                            {!files?.sell_sheets ? (
                                                <label
                                                    className="btn btn-outline-custom-primary btn-pill  btn-sm">
                                                    <input
                                                        type="file"
                                                        name="sell_sheets"
                                                        className="d-none"
                                                        id="sellSheets"
                                                        onChange={handleFileChange}
                                                    />
                                                    <i className="la la-upload"></i>
                                                    Browse
                                                </label>
                                            ) : (
                                                <span
                                                    onClick={(event) => {
                                                        handleRemoveFile("sell_sheets");
                                                        event.stopImmediatePropagation();
                                                    }}
                                                    className="col-6 col-lg-6 d-flex align-items-center gap-1"
                                                >
                                                    <span class="text-truncate">
                                                        {files?.sell_sheets?.substring(
                                                            files?.sell_sheets?.lastIndexOf("/") + 1
                                                        )}
                                                    </span>
                                                    <i className="fa fa-times"></i>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-12 p-0">

                                <div className="row mb-4 col-12 p-0 m-0">
                                    <div class="col-12 row overflow-hidden">
                                        {!files?.product_image_front ? (
                                            <div className="col-6">
                                                <label className="w-100">Product Image (Front)</label>
                                                <label
                                                    className="btn btn-outline-custom-primary btn-pill   btn-sm"
                                                    htmlFor="product_image_front"
                                                >
                                                    <input
                                                        type="file"
                                                        name="product_image_front"
                                                        id="product_image_front"
                                                        className="d-none"
                                                        accept="image/png, image/gif, image/jpeg, image/webp"
                                                        onChange={handleFileChange}
                                                    />
                                                    <i className="la la-upload"></i> Browse
                                                </label>
                                            </div>
                                        ) : (
                                            <div className="col-6">
                                                <label className="w-100">Product Image (Front)</label>
                                                <span
                                                    onClick={() => {
                                                        handleRemoveFile("product_image_front");
                                                    }}
                                                    className="d-flex align-items-center gap-1 flex-wrap"
                                                >
                                                    <span class="text-truncate">
                                                        {files?.product_image_front?.substring(
                                                            files?.product_image_front?.lastIndexOf("/") + 1
                                                        )}
                                                    </span>
                                                    <i className="fa fa-times"></i>
                                                </span>
                                            </div>
                                        )}
                                        {!files?.product_image_back ? (
                                            <div className="col-6">
                                                <label className="w-100">Product Image (Back)</label>
                                                <label
                                                    className="btn btn-outline-custom-primary btn-pill   btn-sm"
                                                    htmlFor="product_image_back"
                                                >
                                                    <input
                                                        type="file"
                                                        name="product_image_back"
                                                        id="product_image_back"
                                                        className="d-none"
                                                        accept="image/png, image/gif, image/jpeg, image/webp"
                                                        onChange={handleFileChange}
                                                    />
                                                    <i className="la la-upload"></i> Browse
                                                </label>
                                            </div>
                                        ) : (
                                            <div className="col-6">
                                                <label className="w-100">Product Image (Back)</label>
                                                <span
                                                    onClick={() => {
                                                        handleRemoveFile("product_image_back");
                                                    }}
                                                    className="d-flex align-items-center gap-1 text-truncate"
                                                >
                                                    <span>
                                                        {files?.product_image_back?.substring(
                                                            files?.product_image_back?.lastIndexOf("/") + 1
                                                        )}
                                                    </span>
                                                    <i className="fa fa-times"></i>
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div class="col-12 row overflow-hidden">
                                        {files?.label_image_front &&
                                            selectedProduct?.category_id !== 3 &&
                                            selectedProduct?.category_id !== 4 ? (
                                            <div className="col-6">
                                                <label className="w-100">Label Image (Front)</label>
                                                <span
                                                    onClick={() => {
                                                        handleRemoveFile("label_image_front");
                                                    }}
                                                    className="d-flex align-items-center gap-1 flex-wrap"
                                                >
                                                    <span>
                                                        {files.label_image_front?.substring(
                                                            files?.label_image_front.lastIndexOf("/") + 1
                                                        )}
                                                    </span>
                                                    <i className="fa fa-times"></i>
                                                </span>
                                            </div>
                                        ) : (
                                            selectedProduct?.category_id !== 3 &&
                                            selectedProduct?.category_id !== 4 &&
                                            selectedCategory?.id !== 3 &&
                                            selectedCategory?.id !== 4 && (
                                                <div className="col-6">
                                                    <label className="w-100">Label Image (Front)</label>
                                                    <label
                                                        className="btn btn-outline-custom-primary btn-pill btn-sm"
                                                        htmlFor="label_image_front"
                                                    >
                                                        <input
                                                            type="file"
                                                            name="label_image_front"
                                                            id="label_image_front"
                                                            className="d-none"
                                                            accept="image/png, image/gif, image/jpeg, image/webp"
                                                            onChange={handleFileChange}
                                                        />
                                                        <i className="la la-upload"></i> Browse
                                                    </label>
                                                </div>
                                            )
                                        )}
                                        {files?.label_image_back &&
                                            selectedProduct?.category_id !== 3 &&
                                            selectedProduct?.category_id !== 4 ? (
                                            <div className="col-6">
                                                <label className="w-100">Label Image (Back)</label>
                                                <span
                                                    onClick={() => {
                                                        handleRemoveFile("label_image_back");
                                                    }}
                                                    className="d-flex align-items-center gap-1 flex-wrap"
                                                >
                                                    <span class="text-truncate">
                                                        {files.label_image_back?.substring(
                                                            files?.label_image_back.lastIndexOf("/") + 1
                                                        )}
                                                    </span>
                                                    <i className="fa fa-times"></i>
                                                </span>
                                            </div>
                                        ) : (
                                            selectedProduct?.category_id !== 3 &&
                                            selectedProduct?.category_id !== 4 &&
                                            selectedCategory?.id !== 3 &&
                                            selectedCategory?.id !== 4 && (
                                                <div className="col-6">
                                                    <label className="w-100">Label Image (Back)</label>
                                                    <label
                                                        className="btn btn-outline-custom-primary btn-pill  btn-sm"
                                                        htmlFor="label_image_back"
                                                    >
                                                        <input
                                                            type="file"
                                                            name="label_image_back"
                                                            id="label_image_back"
                                                            className="d-none"
                                                            accept="image/png, image/gif, image/jpeg, image/webp"
                                                            onChange={handleFileChange}
                                                        />
                                                        <i className="la la-upload"></i> Browse
                                                    </label>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>

                                <div className="row mb-4 col-12">
                                    <div className="col-12">
                                        <label className="w-100">Recipes</label>
                                        <label
                                            className="btn btn-outline-custom-primary btn-pill btn-sm"
                                            htmlFor="recipesFile">
                                            <input
                                                onKe
                                                type="file"
                                                multiple
                                                className="d-none"
                                                id="recipesFile"
                                                name="recipes"
                                                onChange={handleRecipesFileChnage}
                                            />
                                            Browse
                                            <i className="la la-upload"></i>
                                        </label>
                                        <div class="d-flex align-items-start gap-1 flex-column text-truncate">
                                            {recipes?.map((recipe, index) => {
                                                return (<label
                                                    onClick={() => {
                                                        const updatedRecipes = [...recipes];
                                                        updatedRecipes.splice(index, 1);
                                                        setRecipes(updatedRecipes);
                                                    }}
                                                    className="d-flex align-items-center gap-1 flex-wrap overflow-hidden"
                                                >
                                                    <span className="text-truncate">
                                                        {recipe?.substring(
                                                            recipe.lastIndexOf("/") + 1
                                                        )}
                                                    </span>
                                                    <i className="fa fa-times"></i>
                                                </label>)
                                            })}
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <label className="w-100">Logos</label>
                                        {!files?.logos ? (
                                            <label className="btn btn-outline-custom-primary btn-pill btn-sm">
                                                <input
                                                    type="file"
                                                    className="d-none"
                                                    name="logos"
                                                    id="logos"
                                                    accept="image/png, image/gif, image/jpeg"
                                                    onChange={handleFileChange}
                                                />
                                                <i className="la la-upload"></i> Browse
                                            </label>
                                        ) : (
                                            <span
                                                onClick={() => {
                                                    handleRemoveFile("logos");
                                                }}
                                                className="col-6 col-lg-6 d-flex align-items-center gap-1"
                                            >
                                                <span class="text-truncate">
                                                    {files.logos?.substring(
                                                        files?.logos.lastIndexOf("/") + 1
                                                    )}
                                                </span>
                                                <i className="fa fa-times"></i>
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="col-12">
                                    <label className="w-100">Video Links</label>
                                    <input
                                        {...register("videos")}
                                        value={video}
                                        onChange={(e) => setVideo(e.target.value)}
                                        type="text"
                                        className="form-control rounded-pill"
                                        name="videos"
                                        placeholder="Enter video links, comma separated"
                                    />
                                </div>
                                <div className="col-12">
                                    <label className="w-100">Awards / Accolades</label>
                                    <textarea
                                        {...register("awards")}
                                        className="form-control rounded-xl"
                                        style={{ resize: "none" }}
                                        placeholder="Enter awards / accolades, comma separated"
                                    />
                                </div>
                                <div className="col-12">
                                    <label className="w-100">Notes, if any</label>
                                    <textarea
                                        {...register("notes")}
                                        className="form-control rounded-xl"
                                        style={{ resize: "none" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter className="d-flex flex-column ">

                    <button
                        onClick={handleSubmit(handleSubmitProduct)}
                        className="btn btn-pill btn-custom-primary font-weight-bold w-100"
                    >
                        Submit
                    </button>
                    {isDraftRoute && <label
                        type="button"
                        onClick={handleSaveDraft}
                        className="font-weight-bold w-100 mt-3">
                        Save Draft
                    </label>}
                </ModalFooter>
            </Modal>
            <div style={{ position: "absolute", zIndex: 9999 }}>
                <PricingByStateModal
                    selectedStates={selectedStates}
                    setSelectedStates={setSelectedStates}
                    setStatePricing={setStatePricing}
                    toggle={props.toggleEditModal}
                    modal={props.modal}
                    style={{ zIndex: 9999 }}
                    onClose={() => setSecondModalOpen(false)}
                />
            </div>
        </div>
    );
});

export default EditSubmissionModal;
